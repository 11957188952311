import Head from 'next/head';
import type { AppProps } from 'next/app';
import { UserProvider } from '@supabase/supabase-auth-helpers/react'
import { supabaseClient } from '@supabase/supabase-auth-helpers/nextjs'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <main className="container">
      <Head>
        <title>Carrier</title>
        <link rel="icon" href="favicon.png"></link>
        <meta
          name="description"
          content="Read your saved Pocket &amp; Instapaper articles in your inbox."
        />
        <meta name="keywords" content="pocket,instapaper,email,reminder" />
      </Head>

      <style jsx global>{`
        @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css');
        @import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500');
        body {
          font-family: Poppins, sans-serif;
          background-color: #edf0f7;
          font-weight: 400;
        }
      `}</style>

      <div className="row">
        <div className="col-md-8 mx-auto mt-5">
          <h2 className="text-muted mb-4">carrier</h2>
          <UserProvider supabaseClient={supabaseClient}>
            <Component {...pageProps} />
          </UserProvider>
        </div>
      </div>
    </main>
  );
}

export default MyApp;
